.logo,
.logoInv {
  display: flex !important;
}

.logoInv {
  filter: brightness(2.9);
}

@media screen and (max-width: 992px) {
  .mobileLogo,
  .mobileLogoInv {
    display: flex !important;
  }

  .mobileLogoInv {
    filter: brightness(2.9);
  }
}
