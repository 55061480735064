.wrapperContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  background-color: var(--bgPage);
}

.container,
.center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
}

.center {
  align-items: center;
}

.flex,
.flexReverse {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.box {
  display: flex;
  padding: 0.5rem;
  gap: 1rem;
  font-weight: 500;
  flex-direction: column;
}

.maintenanceHeader {
  font-size: 2rem;
  font-weight: bold;
}

.header {
  font-size: 1.5rem;
  font-weight: bold;
}

.link {
  font: inherit;
  font-family: inherit !important;
}

.button {
  font: inherit;
  font-family: inherit !important;
  max-width: 200px;
}

@media screen and (max-width: 992px) {
  .wrapperContainer {
    height: 90%;
    width: 90%;
  }

  .maintenanceImage {
    height: 180px;
    width: 220px;
  }

  .flex,
  .flexReverse {
    align-items: center;
    flex-direction: column;
  }

  .flexReverse {
    flex-direction: column-reverse;
  }
}
