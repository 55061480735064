@media (prefers-color-scheme: dark) {
  :root {
    --primary: #00bbff;
    --divider: #203645;
    --black: #000;
    --white: #fcfcfc;
    --scroll-thumb: rgba(172, 172, 172, 0.858);
    --border: rgba(200, 200, 200, 0.5);
    --red: rgba(214, 31, 90, 1);
    --spacing-default: 2rem;
    --shadow-card: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 2px 8px rgba(0, 0, 0, 0.04);
    --shadow-popover: 0px 8px 80px rgba(2, 2, 15, 0.16), 0px 4px 24px rgba(2, 2, 15, 0.08),
      0px 6px 40px rgba(2, 2, 15, 0.04);
    --sidebarOpen: 300px;
    --sidebarClosed: 0px;
    --headerHeight: 80px;
    --headerItemHeight: 43px;
    --headerItemWidth: 43px;
    --popoverPadding: 23px;
    /* --bgPage: #181830; */
    /* This matches light mode for initial loading, will be switched after app load */
    --bgPage: #f0f3f7;
    --layoutWidth: 1030px;
    --header-z-index: 5000;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --primary: #1362e2;
    --black: #000;
    --white: #fcfcfc;
    --divider: #abc6d8;
    --scroll-thumb: rgba(172, 172, 172, 0.858);
    --border: rgba(200, 200, 200, 0.5);
    --red: rgba(214, 31, 90, 1);
    --spacing-default: 2rem;
    --shadow-card: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 2px 8px rgba(0, 0, 0, 0.04);
    --shadow-popover: 0px 8px 80px rgba(2, 2, 15, 0.16), 0px 4px 24px rgba(2, 2, 15, 0.08),
      0px 6px 40px rgba(2, 2, 15, 0.04);
    --sidebarOpen: 300px;
    --sidebarClosed: 0px;
    --headerHeight: 80px;
    --headerItemHeight: 43px;
    --headerItemWidth: 43px;
    --popoverPadding: 23px;
    --bgPage: #f0f3f7;
    --layoutWidth: 1030px;
    --header-z-index: 5000;
  }
}

html {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.01rem;
  box-sizing: border-box;
  text-size-adjust: 100%;
  background-color: var(--bgPage);
  overscroll-behavior-y: none;
}
body {
  margin: 0;
  width: 100%;
  color: var(--black);
  background-color: var(--bgPage);
  overflow-x: hidden;
  overscroll-behavior-y: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  background-color: var(--white);
}

body::-webkit-scrollbar-corner {
  background-color: var(--white);
}

body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scroll-thumb);
  min-height: 24px;
  border: 3px solid var(--white);
}

input[type='date'],
input[type='time'] {
  -webkit-appearance: none;
  appearance: none;
}

/* override Vega default z-index */
.vega-dropdown {
  z-index: 500 !important;
}

.shownProgress {
  visibility: visible;
}

.hiddenProgress {
  visibility: hidden;
}

.show-on-mobile {
  display: none !important;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

h3 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-block-start: 0rem;
  margin-block-end: 0rem;
}

.inputThird {
  width: 31%;
}

.inputHalf {
  width: 48%;
}

.inputFull {
  width: 100%;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.recharts-cartesian-axis-tick-value {
  max-width: 20px;
}

/* fade-in/scroll-in utility classes */
.fade-in-section {
  opacity: 0;
  /* transform: translateY(1vh); */
  visibility: hidden;
  transition: opacity 1.3s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}

.scroll-in-section {
  visibility: visible;
  opacity: 1;
  transform: none;
}

.scroll-in-section.is-visible {
  visibility: visible;
  opacity: 1;
  transform: none;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.information-popper-icon {
  padding: 0 0.5rem;
  transition: none;
}

.information-pane {
  max-width: 31rem;
  border-radius: 7px;
  box-shadow: var(--shadow-popover);
  display: flex;
  flex-direction: row;
}

.information-close-icon {
  padding-top: 0.5rem;
  padding-left: 1rem;
  position: absolute;
  right: 1.2rem;
}

/* width */
.information-pane::-webkit-scrollbar {
  width: 0.3rem;
}

/* Track */
.information-pane::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.information-pane::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
}

/* Notification Custom Classes for Global */
.api-banner-button {
  font-weight: 700;
  border: 1px solid #203645;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 30px;
  cursor: pointer;
  text-align: center;
}

.api-banner-button:hover {
  opacity: 0.8;
}

.api-banner-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

/* Notification banner outline button */
vega-button.banner-button-outline::part(button) {
    background: transparent;
    border-color: #000;
    color: #000;
}
vega-button.banner-button-outline::part(button):hover {
    background: rgba(0, 0, 0, 0.1);
}

@media (prefers-reduced-motion) {
  .fade-in-section,
  .scroll-in-section {
    opacity: 1;
    visibility: visible;
    transition: none;
    transform: none;
  }
}

@media screen and (max-width: 992px) {
  :root {
    --headerItemHeight: 38px;
    --headerItemWidth: 38px;
    --headerHeight: 65px;
    --sidebarOpen: 85%;
  }
  .html {
    font-size: 14px;
  }
  .inputThird,
  .inputHalf {
    width: 100%;
  }

  .api-banner-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .scroll-in-section {
    opacity: 0;
    transform: translateY(-70px);
    visibility: hidden;
    transition: opacity 0.5s ease-out, transform 0.4s ease-out;
    will-change: opacity, visibility;
  }

  .body {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .error-auth {
    padding: 10px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

/* This overrides the vega nav zindex to put it under our modals */
vega-left-nav {
  z-index: 5004 !important;
}

/* background used for the nav */
vega-backdrop {
  z-index: 5003 !important;
}

/* backdrop used for the modal */
vega-backdrop.modal-backdrop {
  z-index: 5005 !important;
}
