.loginContainer > div {
  margin-top: 1.5rem;
}

.loading {
  margin-top: 3rem !important;
  font-size: 0.9rem;
  color: #6b747d;
}

.myAccountMessage {
  font-size: 1.2rem;
}

.loginContainer {
  width: 100%;
  height: 100vh;
  padding: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  background-color: var(--bgPage);
}

.loginContainerFade {
  /* To prevent flashing, should be about 200ms longer than the timer on the loginSplash component */
  animation: fadeOut ease 400ms;
  -webkit-animation: fadeOut ease 400ms;
  -moz-animation: fadeOut ease 400ms;
  -o-animation: fadeOut ease 400ms;
  -ms-animation: fadeOut ease 400ms;
  -webkit-animation-delay: 0ms;
  -moz-animation-delay: 0ms;
  -o-animation-delay: 0ms;
  animation-delay: 0ms;
}

.loadingBar {
  min-width: 250px;
}

@keyframes fadeOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
