.layoutCard {
  width: 1230px;
  padding: 24px;
  border-radius: 16px;
}

.layoutCardTransparent {
  width: 1230px;
  padding: 0 0 0 0;
}

.noPadding {
  padding: 0px;
}

.flexLength {
  width: 100%;
}

@media screen and (max-width: 1920px) {
  .layoutCardTransparent {
    width: 100%;
  }

  .layoutCard {
    width: 100%;
  }
}

.mediumMaxWidth {
  max-width: 1325px;
}

@media screen and (max-width: 1340px) {
  .layoutCard {
    padding: 20px;
  }

  .layoutCardTransparent {
    padding: 0px;
  }

  .transparentCard {
    padding: 0px;
  }
}
